import { Component } from "react"
import { connect } from 'react-redux'
import { ResultsTableData } from "../../../actions/types/portfolioDesigner"
import { ReduxState } from "../../../reducers"
import { Box } from "@mui/material"
import { ChartOptions } from "../../../components/OptimizedPortfolio/chartOptions"
import Chart from "../../../components/common/Chart"
import ExtendedCommonTable from "../../../components/common/ExtendedCommonTable"
import { TableRowData } from "../../../common/types"
import Text from "../../../components/common/Text"
import { ClipLoader } from "react-spinners"
import { onEffChartMarkerClicked } from "../../../actions/portfolioDesigner"

export interface EfficientFrontierProps {
    effChart: ChartOptions
    effTable: {
        rows: TableRowData[]
        columns: string[]
    }
    runningEfficencyFrontier: boolean
    onEffChartMarkerClicked: (data: any) => void
}
export interface EfficientFrontierState { 
    effChart?: ChartOptions
}
class EfficientFrontier extends Component<EfficientFrontierProps, EfficientFrontierState> {
    mainView?: any
    resultsRef?: any

    constructor(props: EfficientFrontierProps) {
        super(props)

        this.state = {
            effChart: this.addClickHandlersToChartOptions(props.effChart),
        };
    }

    addClickHandlersToChartOptions = (chartOptions: ChartOptions): ChartOptions | undefined => {
        if (!chartOptions) return undefined
        return {
            ...chartOptions,
            plotOptions: {
                scatter: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.onMarkerClicked
                        }
                    }
                },
                spline: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.onMarkerClicked
                        }
                    }
                }
            },
        }
    }

    componentDidUpdate(prevProps: Readonly<EfficientFrontierProps>, prevState: Readonly<EfficientFrontierState>, snapshot?: any): void {
        if (prevProps.effChart !== this.props.effChart) {
            this.setState({ effChart: this.addClickHandlersToChartOptions(this.props.effChart) })
        }
    }

    onMarkerClicked = ({point}: any) => {
        const { markerData } = point
        this.props.onEffChartMarkerClicked(markerData)
    }

    render() {
        const { effTable, runningEfficencyFrontier } = this.props
        const { effChart: effChartOptions } = this.state
        if (runningEfficencyFrontier || !effChartOptions || !effTable) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', minHeight: '25rem'}}>
                    <Box><Text>Calculating...</Text></Box>
                    <Box><ClipLoader /></Box>
                </Box>
            )
        }
        const { rows, columns } = effTable
        return (
            <Box>
                <Chart id={'effChart'} sx={{ height: '75rem'}} chartOptions={effChartOptions} zoomOptions={{ enabled: true }} />
                <Box sx={{  mt: '5rem', display: 'flex', justifyContent: 'center' }}>
                    <ExtendedCommonTable
                        sx={{ maxWidth: '125rem',}}
                        headerColumns={
                        [
                                {
                                    key: '',
                                    children: '',
                                    colSpan: 1,
                                },
                                ...columns.map((column: string) => {
                                    return {
                                        key: column,
                                        children: column,
                                        sx: {
                                            textAlign: 'center',
                                        }
                                    }
                                })
                        ]
                        }
                        centerValues={true}
                        rows={rows}
                    />
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { effChart, effTable, runningEfficencyFrontier } = portfolioDesigner

    return { effChart, effTable, runningEfficencyFrontier }
}

export default connect(mapStateToProps, { onEffChartMarkerClicked })(EfficientFrontier as  any)