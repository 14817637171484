import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { SketchPicker } from 'react-color';
import { FieldItem, TextFieldItem } from '../common/FormField';
import { Firm } from '../../actions/types/account';
import { formatPhoneNumber } from '../../common/utils';
import Button from '../common/Button';
import { addError, addStatusNotification, updateNotification } from '../../actions/notifications';
import { AccountPanel } from './AccountPanel';
import { UpdateFirmRequest } from '../../actions/types/requests';
import { StatusNotificationItem } from '../../actions/types/notifications';
import { updateFirm, updateFirmLogo } from '../../actions/account';
import { useAuth } from '../../common/hooks/useAuth';
import { logoHorizontal } from '../../assets';
import ConfirmModal from '../common/ConfirmModal';

interface FirmDetailsProps extends Firm {
    onFirmUpdated: (update: UpdateFirmRequest) => void
    updateFirm: (payload: UpdateFirmRequest) => void
    addError: (title: string, message: string) => void
    addStatusNotification: (message: string) => Promise<StatusNotificationItem>
    updateNotification: (notification: StatusNotificationItem) => void
}

const FirmDetails: FunctionComponent<FirmDetailsProps> = ({ onFirmUpdated, updateNotification, addStatusNotification, name: propName = '', address: propAddress = '', website: propWebsite = '', ips_disclosures: propIpsDisclosures = '', phone: propPhone = '', firm_description: propFirmDescription, report_header_disclosures: propReportHeaderDisclosure, report_disclosures: propReportDisclosure, firm_primary_color: propFirmPrimaryColor = '', firm_secondary_color: propFirmSecondaryColor = '' }) => {
    const { details: userDetails, updateLogo } = useAuth()
    const [name, setName] = React.useState<string>(propName || '')
    const [address, setAddress] = React.useState<string>(propAddress || '')
    const [website, setWebsite] = React.useState<string>(propWebsite || '')
    const [ipsDisclosures, setIpsDisclosures] = React.useState<string>(propIpsDisclosures || '')
    const [firmDescription, setFirmDescription] = React.useState<string>(propFirmDescription || '')
    const [firmPrimaryColor, setFirmPrimaryColor] = React.useState<string>(propFirmPrimaryColor || '')
    const [firmSeconaryColor, setFirmSecondaryColor] = React.useState<string>(propFirmSecondaryColor || '')
    const [reportDisclosures, setReportDisclosures] = React.useState<string>(propReportDisclosure || '')
    const [reportHeaderDisclosures, setReportHeaderDisclosures] = React.useState<string>(propReportHeaderDisclosure || '')
    const [phone, setPhone] = React.useState<string>(formatPhoneNumber(propPhone || ''))
    const [dirty, setDirty] = React.useState<boolean>(false)
    const [logoUploadProgress, setLogoUploadProgress] = React.useState<number>(0)
    const [logoFile, setLogoFile] = React.useState<File>()
    const [logoSource, setLogoSource] = React.useState<any>(userDetails?.logo)
    const [firmColorPickerType, setFirmColorPickerType] = React.useState<'primary' | 'secondary' | undefined>()
    const [colorPickerColor, setColorPickerColor] = React.useState<string | undefined>()

    React.useEffect(() => {
        setLogoSource(userDetails?.logo ?? logoHorizontal)
    },[userDetails?.logo])
    
    const getUpdatePayload = React.useCallback(() => {
        let updatePayload: UpdateFirmRequest = { }
        if(name && name !== propName) {
            updatePayload = { ...updatePayload, name }
        }
        if(address && address !== propAddress) {
            updatePayload = { ...updatePayload, address }
        }
        if(phone && phone !== formatPhoneNumber(propPhone)) {
            updatePayload = { ...updatePayload, phone }
        }
        if(website && website !== propWebsite) {
            updatePayload = { ...updatePayload, website }
        }
        if(ipsDisclosures && ipsDisclosures !== propIpsDisclosures) {
            updatePayload = { ...updatePayload, ips_disclosures: ipsDisclosures }
        }
        if(firmDescription && firmDescription !== propFirmDescription) {
            updatePayload = { ...updatePayload, firm_description: firmDescription }
        }
        if(reportHeaderDisclosures && reportHeaderDisclosures !== propReportHeaderDisclosure) {
            updatePayload = { ...updatePayload, report_header_disclosures: reportHeaderDisclosures }
        }
        if(reportDisclosures && reportDisclosures !== propReportDisclosure) {
            updatePayload = { ...updatePayload, report_disclosures: reportDisclosures }
        }
        if(firmPrimaryColor && firmPrimaryColor !== propFirmPrimaryColor) {
            updatePayload = { ...updatePayload, firm_primary_color: firmPrimaryColor }
        }
        if(firmSeconaryColor && firmSeconaryColor !== propFirmSecondaryColor) {
            updatePayload = { ...updatePayload, firm_secondary_color: firmSeconaryColor }
        }
        
        return updatePayload
    },[name, address, phone, website, ipsDisclosures, firmDescription, reportDisclosures, reportHeaderDisclosures, firmPrimaryColor, firmSeconaryColor])

    const uploadFirmDetails = React.useCallback(async() => {
        const updatePayload: UpdateFirmRequest = getUpdatePayload()
        const hasFieldUpdates = Object.keys(updatePayload).length > 0

        if(!hasFieldUpdates) {
            return
        }

        const notification = await addStatusNotification('Saving Firm details!')
        const { success, message = '' } = await updateFirm(updatePayload)
    
        if(success) {
            updateNotification({ ...notification, content: 'Succesfully saved Firm details!', duration: 2000, showSpinner: false,  })
            onFirmUpdated(updatePayload)
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Firm Update', content: message, duration: 2000  })
        }
    }, [getUpdatePayload])

    const uploadLogo = React.useCallback(async() => {
        const hasLogoUpdate = !!logoFile

        if(!hasLogoUpdate) {
            return
        }

        const notification = await addStatusNotification('Uploading Firm Logo!')
        const { success, message, logo: logoUpdate } = await updateFirmLogo(logoFile, setLogoUploadProgress)
    
        if(success) {
            updateNotification({ ...notification, content: 'Succesfully uploaded Firm Logo!', duration: 2000, showSpinner: false,  })
            if(logoUpdate) {
                updateLogo(logoUpdate)
            }
            setLogoFile(undefined)
            
        } else {
            updateNotification({ ...notification, type: 'Error', title: 'Firm Logo Update', content: message || '', duration: 2000  })
        }
    }, [getUpdatePayload, logoSource])

    const onUpdate = React.useCallback(async() => {
        await Promise.all([uploadFirmDetails(),uploadLogo()])

    },[uploadFirmDetails, uploadLogo ])

    React.useEffect(() => {
        if(name && name !== propName) {
            setDirty(true)
        } else if(address && address !== propAddress) {
            setDirty(true)
        } else if(phone && phone !== formatPhoneNumber(propPhone)) {
            setDirty(true)
        } else if(website && website !== propWebsite) {
            setDirty(true)
        } else if(ipsDisclosures && ipsDisclosures !== propIpsDisclosures) {
            setDirty(true)
        } else if(firmDescription && firmDescription !== propFirmDescription) {
            setDirty(true)
        } else if(reportHeaderDisclosures && reportHeaderDisclosures !== propReportHeaderDisclosure) {
            setDirty(true)
        } else if(reportDisclosures && reportDisclosures !== propReportDisclosure) {
            setDirty(true)
        } else if(logoFile) {
            setDirty(true)
        } else if(firmPrimaryColor && firmPrimaryColor !== propFirmPrimaryColor) {
            setDirty(true)
        } else if(firmSeconaryColor && firmSeconaryColor !== propFirmSecondaryColor) {
            setDirty(true)
        } else {
            setDirty(false)
        }
    }, [name, address, phone, website, ipsDisclosures, logoFile, firmDescription, reportHeaderDisclosures, reportDisclosures,firmPrimaryColor, firmSeconaryColor, propFirmSecondaryColor, propFirmPrimaryColor, propName, propAddress, propPhone, propWebsite, propIpsDisclosures, propReportHeaderDisclosure, propReportDisclosure, propFirmDescription])

    React.useEffect(() => {
        setName(propName || '')
        setAddress(propAddress || '')
        setWebsite(propWebsite || '')
        setIpsDisclosures(propIpsDisclosures || '')
        setPhone(formatPhoneNumber(propPhone || ''))
        setFirmDescription(propFirmDescription || '')
        setReportDisclosures(propReportDisclosure || '')
        setReportHeaderDisclosures(propReportHeaderDisclosure || '')
        setFirmPrimaryColor(propFirmPrimaryColor || '')
        setFirmSecondaryColor(propFirmSecondaryColor || '')

    }, [propName, propAddress, propWebsite, propPhone, propIpsDisclosures, propFirmDescription, propReportDisclosure, propReportHeaderDisclosure, propFirmPrimaryColor, propFirmSecondaryColor])
    
    const onFormatPhone = React.useCallback((value) => {
        setPhone(formatPhoneNumber(value))
    }, [phone])

    const onLogoChange = React.useCallback((e) => {
        if(e.target?.files?.length !== 1) {
            setLogoFile(undefined)
            setLogoSource(logoHorizontal)
            return
        }
        const file = e.target.files[0];
        setLogoFile(file)
    
        const reader = new FileReader();
        reader.onloadend = function() {
            setLogoSource(reader.result)
        }
        reader.readAsDataURL(file);
    },[])

    const onColorPickerColorChanged = React.useCallback((color: any) => {
        setColorPickerColor(color.hex)
    }, [])

    const onShowFirmPrimaryColorPicker = React.useCallback(() => {
        setColorPickerColor(firmPrimaryColor)
        setFirmColorPickerType('primary')
    }, [firmPrimaryColor])
    const onShowSecondaryFirmColorPicker = React.useCallback(() => {
        setColorPickerColor(firmSeconaryColor)
        setFirmColorPickerType('secondary')
    }, [])
    const onConfirmColorPicker = React.useCallback(() => {
        let setColorFunction
        if (firmColorPickerType === 'primary') {
            setColorFunction = setFirmPrimaryColor
        }
        if (firmColorPickerType === 'secondary') {
            setColorFunction = setFirmSecondaryColor
        }
        if (!setColorFunction) {
            return
        }
        setColorFunction(colorPickerColor || '')
        setFirmColorPickerType(undefined)
    }, [firmColorPickerType, colorPickerColor])
    const onCloseColorPicker = React.useCallback(() => {
        setFirmColorPickerType(undefined)
    }, [firmColorPickerType, colorPickerColor])

    return (
        <AccountPanel
            title={'Firm Details'}>
            <ConfirmModal
                title='Firm Color'
                visible={firmColorPickerType !== undefined}
                onCancel={onCloseColorPicker}
                onConfirm={onConfirmColorPicker}
                size={'sm'}
                confirmLabel={'Save'}>
                    <Box sx={{ 
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .sketch-picker': {
                            boxShadow: 'unset !important'
                        }
                    }}>
                        <SketchPicker
                            color={ colorPickerColor }
                            onChange={ onColorPickerColorChanged }
                        />
                    </Box>
            </ConfirmModal>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'Firm Name'}
                    value={name}
                    onValueChange={setName}
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    title={'Firm Address'}
                    value={address}
                    onValueChange={setAddress}
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    type={'phone'}
                    title={'Firm Phone'}
                    value={phone}
                    onValueChange={onFormatPhone}
                    inline
                />
                <TextFieldItem
                    sx={{ 
                        width: {
                            xs: '100%',
                            md: '35rem'
                        }
                    }}
                    type={'website'}
                    title={'Firm Website'}
                    value={website}
                    onValueChange={setWebsite}
                    inline
                />
                <TextFieldItem
                    sx={{
                        width: {
                            xs: '100%',
                            md: '35rem',
                        },
                        height: '10rem',
                        alignItems: 'flex-start',
                        '& textarea': {
                            width: {
                                xs: '100% !important',
                                md: '35rem !important'
                            },
                            height: '8rem !important',
                        },
                    }}
                    title={'Firm Description'}
                    value={firmDescription}
                    onValueChange={setFirmDescription}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    inline
                />
                
                <TextFieldItem
                    sx={{
                        width: {
                            xs: '100%',
                            md: '35rem',
                        },
                        height: '10rem',
                        alignItems: 'flex-start',
                        '& textarea': {
                            width: {
                                xs: '100% !important',
                                md: '35rem !important'
                            },
                            height: '8rem !important',
                        },
                    }}
                    title={'IPS Disclosures'}
                    value={ipsDisclosures}
                    onValueChange={setIpsDisclosures}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    inline
                />
                <TextFieldItem
                    sx={{
                        width: {
                            xs: '100%',
                            md: '35rem',
                        },
                        height: '10rem',
                        alignItems: 'flex-start',
                        '& textarea': {
                            width: {
                                xs: '100% !important',
                                md: '35rem !important'
                            },
                            height: '8rem !important',
                        },
                    }}
                    title={'Report Header Disclosure'}
                    value={reportHeaderDisclosures}
                    onValueChange={setReportHeaderDisclosures}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    inline
                />
                <TextFieldItem
                    sx={{
                        width: {
                            xs: '100%',
                            md: '35rem',
                        },
                        height: '10rem',
                        alignItems: 'flex-start',
                        '& textarea': {
                            width: {
                                xs: '100% !important',
                                md: '35rem !important'
                            },
                            height: '8rem !important',
                        },
                    }}
                    title={'Report Disclosure'}
                    value={reportDisclosures}
                    onValueChange={setReportDisclosures}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    inline
                />
                <FieldItem title={'Firm Logo'} inline>
                    <Box 
                        sx={{ 
                            width: '35rem',
                            marginLeft: {
                                xs: undefined,
                                md: '1.5rem',
                            },
                        }}>
                        <Box sx={{
                            position: 'relative',
                            width: '22rem',
                            height: '11rem',
                            border: '1px dotted #000',
                            '& img': {
                                border: 0,
                                width: '22rem',
                                height: '11rem',
                                padding: '.5rem',
                                objectFit: 'contain',
                            },
                            '& input': {
                                fontSize: 0,
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                opacity: 0,
                                width: '100%',
                                height: '100%',
                            },
                            '& progress': {
                                width: '22rem',
                            }
                        }}>
                            <img src={logoSource}></img>
                            <input type="file" name="logo" accept=".jpg,.jpeg,.png," onChange={onLogoChange}></input>
                            {logoFile && <progress value={logoUploadProgress} max="100"></progress>}
                        </Box>
                    </Box>
                </FieldItem>
                <FieldItem sx={{  }} title={'Firm Color'} inline>
                    <Box 
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '1rem',
                            width: {
                                xs: '100%',
                                md: '35rem',
                            },
                        }}>
                            <Box onClick={onShowFirmPrimaryColorPicker} sx={{ width: '5rem', height: '5rem', backgroundColor: firmPrimaryColor, border: '.1rem solid' }}>
                                
                            </Box>
                            <Box onClick={onShowSecondaryFirmColorPicker} sx={{ marginLeft: '1rem', width: '5rem', height: '5rem', backgroundColor: firmSeconaryColor, border: '.1rem solid' }}>
                                
                            </Box>
                    </Box>
                </FieldItem>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem', paddingRight: '1rem', }}>
                <Button 
                    sx={{ width: '15rem', height: '4.5rem', minHeight: 'unset' }}
                    title={'Update'}
                    onClick={onUpdate}
                    disabled={!dirty}
                />
            </Box>
        </AccountPanel>
    )
}

export default connect(null, { addStatusNotification, addError, updateNotification  })(FirmDetails)