import React, { useCallback, useEffect } from 'react'
import { SelectOption } from '../../../common/types';
import { TimeRange, TimeRangeDates, QUARTERLY_TIME_RANGES_OPTIONS, getTimeRangeDates } from '../../../components/common/ranges';
import moment from 'moment';
import { Select } from '../../../components/common/styled/Select';


interface Props {
    value?: TimeRange | null;
    disabled?: boolean;
    placeholder?: string;
    onItemPicked: (dates: TimeRangeDates) => void;
}

const QUARTER_DATE_MAP: { [key: string]: [string, string]} = {
    'Q1 2024':['2023-12-31','2024-03-31'],
    'Q2 2024':['2024-03-31','2024-06-30'],
    '2024 (YTD)':['2023-12-31','2024-12-31'],
    '2023':['2022-12-31','2023-12-31'],
}

export const QUARTER_DATE_OPTIONS: SelectOption<TimeRangeDates>[] = Object.keys(QUARTER_DATE_MAP).map((key) => {
    const quarter = QUARTER_DATE_MAP[key];
    const start_date = moment(quarter[0]).toDate();
    const end_date = moment(quarter[1]).toDate();

    return {
        label: key,
        value: key,
        item: {
            start_date,
            end_date,
        }
    }
})


export const QuarterlyDropdown: React.FC<Props> = ({ placeholder, value, disabled, onItemPicked: onItemPickedProp }) => {

    const onItemPicked = useCallback((value: SelectOption<TimeRangeDates>) => {
        if (!value.item) {
            return;
        }
        onItemPickedProp(value.item)
    }, [onItemPickedProp]);

    return (
            <Select
                placeholder={placeholder || 'Quarter Presets'}
                options={QUARTER_DATE_OPTIONS}
                onChange={onItemPicked}
                value={null}
                isDisabled={disabled}
            />
    )
}