import moment from "moment";
import { LooseObject, SelectOption } from "../../common/types";

export interface TickerBase {
  id: number;
  ric: string;
  symbol: string;
  symbol_display: string;
  asset_class: string;
}

export interface Ticker extends TickerBase {
  name: string;
  description: string;
  domicile: string;
  cusip: string;
  isin: string;
  asset_universe?: string;
  fund_type?: string;
  launch_date?: string;
  expense_ratio?: number;
  div_yield_12m?: number;
}
export interface ETFTicker extends Ticker {
  etf_desc: string;
  etf_asset_class: string;
  etf_launch_date: string;
  etf_expense_ratio: number;
}

export interface TickerProxy {
  ticker?: Ticker;
  enabled: boolean;
}
export interface SelectOptionTicker extends SelectOption<Ticker> {
  ticker: Ticker;
}

export interface TickerAllocation {
  id: string
  ticker?: Ticker ,
  weight?: number
}

export interface Weights { [key: string]: number }
export interface TickerIds { [key: string]: string }

export interface Portfolio {
  ids: TickerIds;
  weights: Weights;
  rebalancingFrequency?: string;
}

export interface AnalyzePortfolioParameters {
  strategyName: string
  current: TickerAllocation[] 
  proposed: TickerAllocation[]
  backTestingStart: string
  backTestingEnd: string
  currentBalancingFrequency: string
  proposedBalancingFrequency: string
  requestId: string
}

export interface HistoricalAnalysisParameters {
	id: string;
	startDate: string;
	endDate: string;
}

export interface AnalysisData {
  columns: string[];
  index: string[];
  data: number[][];
}

export interface PerformanceAttributionData {
  totalAttribution: AnalysisData;
  assetAllocation: AnalysisData;
  securitySelection: {
    [key: string]: AnalysisData;
  };
}


export interface AnalyzePortfolioRequest {
  requestId: string,
  strategyName?: string
  strategy: Portfolio;
  benchmark: Portfolio;
  backTestingStart: string
  backTestingEnd: string
}

export interface KeyStatistics extends ResultsTableData {
  Benchmark: {
    'Cumulative Return': string;
    'Annualized Return': string;
    'Annualized Volatility': string;
    'Maximum Drawdown': string;
    'Ann. Ret. / Ann. Vol.': string;
    'Ex Ante TE': null;
  };
  Strategy: {
    'Cumulative Return': string;
    'Annualized Return': string;
    'Annualized Volatility': string;
    'Maximum Drawdown': string;
    'Ann. Ret. / Ann. Vol.': string;
    'Ex Ante TE': string;
  };
}

export interface AllHistoricalEventsCumulativeReturn {
  Benchmark: {
    'COVID 2020': string;
  };
  Strategy: {
    'COVID 2020': string;
  };
}

export interface Return {
  Strategy: {
    [timestamp: string]: number;
  };
  Benchmark: {
    [timestamp: string]: number;
  };
}

export interface StrategyExposures {
  cash: {
    [timestamp: string]: number;
  };
  [key: string]: {
    [timestamp: string]: number;
  };
}

export interface PerformanceAttribution {
  [key: string]: {
    [timestamp: string]: number;
  };
}

export interface ResultResponse {
  success: boolean;
  error?: string;
  durations?: string[]
}

export interface PriceRange {
  startDate: string;
  endDate: string;
}

export interface TickerPriceRange {
  [key: string]: PriceRange;
}

export interface ReportResource {
  name: string;
  category: string;
  section: number;
  priority: number;
}
export interface ChartResource extends ReportResource {
  type: 'chart'
  url: string;
  long?: boolean;
}
export interface ResultsTableData extends ReportResource {
  type: 'table'
  data: {
    Strategy: {
      [key: string]: string | null;
    };
    Benchmark: {
      [key: string]: string | null;
    };
  };
}

export interface GeneralTableData extends ReportResource {
  data: {
    [key: string]: {
      [key: string]: string | null;
    };
  }
}
export interface GeneralResultTableData extends ReportResource {
  type: 'general_table'
  data: GeneralTableData[];
  excludeKeys?: string[];
  boldedKeys?: string[];
  seperateAfterKeys?: string[];
}
export interface CustomResource extends ReportResource {
  type: 'highchart';
  options: LooseObject;
}
export type ReportResourceItem = ChartResource | ResultsTableData | CustomResource;

export interface DateLogs {
  [key: string]: string | string[];
}

export interface AnalyzeResultsDTO extends ResultResponse {
  reportUrl: string;
  excelUrl: string;
  simulatedPortfolioValuesId: string;
  dates: DateLogs;
  charts: ChartResource[]
  tables: ResultsTableData[]
  hypotheticalDataUsed: boolean;
  missingPrices?: string[]
  correlationMatrix: CorrelationMatrix;
  performanceAttribution: PerformanceAttributionData;
  portfolioOptimizationOutputs: {
    [key: string]: any
  }
  portfolioOptimizationTable: {
    [key: string]: any
  }
}

export interface PortfolioOptimizationDTO extends ResultResponse {
  portfolioOptimizationSeries: {
    [key: string]: any
  }
  portfolioOptimizationTable: {
    [key: string]: any
  }
}

export interface ResultViewKeys {
  [key: string]: string[]
}

export interface HistoricalAnalysisResultsDTO extends ResultResponse {
  'Custom Range'?: {
    Strategy: string;
    Benchmark: string;
  }
}

export type PortfolioDesignerConfigurationType = 'benchmark' | 'portfolio' | 'financial_plan_needs' | 'account'

export type PortfolioDesignerConfigurationSection = 'model' | 'configuration'
export interface PortfolioDesignerConfiguration {
  id: number
  name: string;
  type: PortfolioDesignerConfigurationType;
  section: PortfolioDesignerConfigurationSection,
  discountRate: number;
  isSystem?: boolean;
  rebalancingFrequency: string;
  portfolio: TickerAllocation[] | TickerAllocationFlat[];
}

export interface TickerAllocationFlat {
  id?: string
  ticker: string,
  weight: number,
}

export const REBALANCING_FREQUENCY: SelectOption[] = [
  { label: 'Buy & Hold', value: 'buy&hold'},
  { label: 'Daily', value: 'daily'},
  { label: 'Weekly', value: 'weekly'},
  { label: 'Monthly', value: 'monthly'},
  { label: 'Quarterly', value: 'quarterly'},
  { label: 'Semi-Annually', value: 'semi_annually'},
  { label: 'Annually', value: 'annually'},
]

export const DEFAULT_OPTION: SelectOption = { label: 'Quarterly', value: 'quarterly'}

export const TYPE_LABEL: { [key: string]: string } = {
  'benchmark': 'Benchmark',
  'portfolio': 'Portfolio',
}
export const TYPE_TABLE_MAPPING: { [key: string]: string } = {
  'benchmark': 'Benchmark',
  'portfolio': 'Strategy',
}

export interface ActionStatus {
  id: string;
  user_id: number;
  originating_id: string;
  action: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface ActionStatusDTO extends ResultResponse {
  status: ActionStatus;
}


export interface PortfolioSnapshotParameters {
  current: TickerAllocation[] 
  proposed: TickerAllocation[]
}

export interface PortfolioSnapshotRequest {
  strategy: Portfolio;
  benchmark: Portfolio;
}
export interface PortfolioSnapshot {
  snapshots: SnapshotItems
}

export interface AllocationBreakdown {
  [key: string]: string | null;
}

export interface PortfolioAllocationBreakdown {
  benchmark: AllocationBreakdown;
  strategy: AllocationBreakdown;
}

export interface MetricDataMapped {
  distribution_yield: {
    [key: string]: number | null;
  }
  duration: {
    [key: string]: number | null;
  }
  expense_ratio: {
    [key: string]: number | null;
  }
  price_over_earnings: {
    [key: string]: number | null;
  }
}

export interface PriceEarningsBuckets {
  0: number;
  5: number;
  10: number;
  15: number;
  20: number;
  25: number;
}
export interface PortfolioSnapshotResultsDTO extends ResultResponse {
  benchmark: PortfolioSnapshot
  strategy: PortfolioSnapshot
  correlationMatrix: CorrelationMatrix
  missingPrices?: string[]
  metrics: MetricDataMapped;
  asset_class: PortfolioAllocationBreakdown
  fixed_income_credit_quality: PortfolioAllocationBreakdown
  fixed_income_sectors: PortfolioAllocationBreakdown
  fixed_income_maturity: PortfolioAllocationBreakdown
  equity_sectors: PortfolioAllocationBreakdown
  equity_regions: PortfolioAllocationBreakdown
  price_over_earnings_mapping: {
    benchmark_buckets: PriceEarningsBuckets;
    strategy_buckets: PriceEarningsBuckets;
    strategy_average: number;
    benchmark_average: number;
  }
}
export interface SinglePortfolioSnapshotResultsDTO extends ResultResponse {
  snapshots: SnapshotItems
  correlationMatrix: CorrelationMatrix
  missingPrices?: string[]
  metrics: MetricDataMapped;
}

export interface CorrelationMatrix {
  [key: string]: {
    [key: string]: number | null;
  }
}

export interface SnapshotItems {
  [key: string]: {
    snapshot: {
      [key: string]: {
        [key: string]: string | null;
      }
    }
    formulas: {
      [key: string]: {
        [key: string]: string | null;
      }
    }
    mctr?: string | null
  }
}

export interface KeyPlots {
  [key: string]: number[]
}

export interface EffData {
  [key: string]: number[] | KeyPlots
}

export interface ModelItemOption extends SelectOption {
  item: PortfolioDesignerConfiguration;
}

export interface ReportItem {
  key: string
  label: string
  selected: boolean
  reportKeys?: string[]
}

export interface ReportSet {
  key: string
  label: string
  items: ReportItem[]
  chunks?: number
  readonly?: boolean
}

export interface ReportOptions {
  items: ReportItem[]
  data?: {
    [key: string]: {
      [key: string]: any;
    };
  };
}

export interface SavedReport {
  id: string
  name: string
  items: string[]
}

export const DEFAULT_MIN_DATE = moment('2008-01-01').toDate()
export const DEFAULT_MAX_DATE = moment().subtract(1, "day").toDate();
export const DEFAULT_LIVE_MAX_DATE = moment().toDate();

export const ASSET_CLASS_ORDER: string[] = [
  'Fixed Income',
  'Equity',
  'Alternatives',
  'Commodities',
  'Other',
  'Totals'
]

export const ASSET_ALLOCATION_COLUMN_MAPPING: { [key: string]: string } = {
  'Asset Allocation Effect': 'Asset Allocation',
  'Security Selection Effect': 'Security Selection',
  'Benchmark Return': 'Asset Class Benchmark Return',
  'Interaction Effect': 'Interaction',
  'Stock Selection': 'Security Selection',
}