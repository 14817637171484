import React, { Component } from 'react'
import { Pager, PagerProvider } from '@crowdlinker/react-pager';

import SLR from './SLR';
import { getStandardLivingRisk } from '../../actions/standardLivingRisk';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { Size } from '../common/styled/SizeAwareBox';
import { withClientParameter } from '../common/withClientParameter';
import { hasClientIdChanged } from '../../common/utils';

interface StandardLivingRiskProps {
    getStandardLivingRisk: () => void
    onSLRPage: (index: number) => void
    activeIndex: number
}

interface State {
    pageHeight: number   
}

class StandardLivingRisk extends Component<StandardLivingRiskProps, State> {
    containerRef: any

    constructor(props) {
        super(props)
        this.state = {
            pageHeight: 0
        }
    }

    componentDidMount() {
        this.props.getStandardLivingRisk()
    }

    componentDidUpdate(prevProps) {
        if(hasClientIdChanged(prevProps, this.props)) {
            this.props.getStandardLivingRisk()
        }
    }

    onNextPage = () => {
        const { activeIndex } = this.props
        window.scrollTo({
            top: 250,
            behavior: "smooth"
        });

        this.props.onSLRPage(activeIndex + 1)
    }

    onPrevPage = () => {
        const { activeIndex } = this.props
        window.scrollTo({
            top: 250,
            behavior: "smooth"
        });
        
        this.props.onSLRPage(activeIndex - 1)
    }

    onPageHeight = ({ height: pageHeight }: Size) => {
        if(pageHeight) {
            this.setState({ pageHeight: pageHeight + 50 })
        }
    }

    render() {
        const { activeIndex } = this.props
        const { pageHeight = 400 } = this.state

        return (
                <Box ref={view => this.containerRef = view } style={{ height: pageHeight, overflow: 'visible', position: 'relative' }}>
                    <SLR onComponentSize={this.onPageHeight} />
                </Box>
        )
    }

}

const mapStateToProps = ({ session }) => {
    const activeIndex = session[`standardLivingRiskActiveIndex`]

    return { activeIndex }
}

export default withClientParameter(connect(mapStateToProps, { getStandardLivingRisk })(StandardLivingRisk))