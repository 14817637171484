import React, { Component } from 'react';
import { Table, TableProps } from './Table';
import { Box, TableCell, TableCellProps, TableRow, TableRowProps, IconButton, ButtonBase } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Text from './styled/Text';
import { TableRowData } from '../../common/types';

export interface CommonTableProps extends TableProps {
    noDataMessage?: string;
    rowProps?: TableRowProps;
    valueCellProps?: TableCellProps;
    rows: TableRowData[];
    redNegativeValues?: boolean;
}

interface State {
    expandedRows: Set<string>;
}

export default class CommonTable extends Component<CommonTableProps, State> {
    constructor(props: CommonTableProps) {
        super(props);
        this.state = {
            expandedRows: new Set<string>(),
        };
    }

    toggleRow = (key: string) => {
        const newSet = new Set(this.state.expandedRows);
        if (newSet.has(key)) {
            newSet.delete(key);
        } else {
            newSet.add(key);
        }
        this.setState({ expandedRows: newSet });
    };

    renderRow = (rowData: TableRowData, isSubRow: boolean = false) => {
        const { rowProps = {}, valueCellProps = {}, redNegativeValues } = this.props;
        const { key, title, titleSx = {}, valueSx = {}, titleRowSpan, values, valueKeys, ignoreTitle, seperator, rows: subRows, rowProp } = rowData;
        const shouldIncludeTitle = !ignoreTitle && title;
        const isExpanded = this.state.expandedRows.has(key || '');

        if (seperator) {
            return (
                <TableRow key={key || title} {...rowProps}>
                    <TableCell sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', height: '2.5rem' }} colSpan={this.props.headerColumns?.length}></TableCell>
                </TableRow>
            );
        }

        const rows = [
            <TableRow key={key || title} {...rowProps} {...rowProp}>
                {shouldIncludeTitle && 
                    <Box component={TableCell} rowSpan={titleRowSpan} sx={{ ...titleSx }}>
                        {subRows?.length > 0 && (
                            <ButtonBase onClick={() => this.toggleRow(key || '')}>
                                <IconButton size="small">
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                                <Text sx={{ ...titleSx }}>{title}</Text>
                            </ButtonBase>
                        )}
                        {!subRows?.length && (
                            <Text sx={{ ml: isSubRow ? '2.5rem' : undefined, ...titleSx }}>{title}</Text>
                        )}
                    </Box>
                }
                {values.map((value: any, index: number) => {
                    const cellKey = valueKeys && valueKeys[index] ? valueKeys[index] : `${index}${value}`;
                    const negativeSx = redNegativeValues && `${value}`.startsWith('-') ? { color: 'red' } : {};
                    if (index === 0 && !shouldIncludeTitle && subRows?.length) {
                        return (
                            <TableCell key={cellKey} {...valueCellProps}>
                                <ButtonBase onClick={() => this.toggleRow(key || '')}>
                                    <IconButton size="small">
                                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                    <Text sx={{ ...valueSx, ...negativeSx }}>{value}</Text>
                                </ButtonBase>
                            </TableCell>
                        );
                    }
                    return (
                        <TableCell key={cellKey} {...valueCellProps}><Text sx={{ ml: isSubRow && index === 0 ? '2.5rem' : undefined, ...valueSx, ...negativeSx }}>{value}</Text></TableCell>
                    )
                })}
            </TableRow>
        ];

        if (isExpanded && subRows) {
            subRows.forEach((row: TableRowData ) => {
                rows.push(this.renderRow(row, true) as any);
            });
        }

        return rows;
    };

    render() {
        const { rows, noDataMessage, ...restProps } = this.props;
        return (
            <Table {...restProps}>
                {!rows.length && noDataMessage && 
                    <TableCell sx={{ textAlign: 'center' }} colSpan={restProps.headerColumns?.length}><Text>{noDataMessage}</Text></TableCell>
                }
                {rows.map(rowData => this.renderRow(rowData))}
            </Table>
        );
    }
}
