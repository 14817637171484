import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageContents } from '../Page/PageContents';
import { Page } from '../Page';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { ReduxState } from '../../reducers';
import { getFinancialPlanAssumptions, inflationRateUpdate, updateDiscountRateForModel } from '../../actions/financialPlanAssumptions';
import { Box } from '@mui/material';
import CommonTable from '../common/CommonTable';
import { ModelExpectedReturns, TREASURY_MODEL_RETURNS } from '../../actions/types/financialPlanAssumptions';
import { StyledInput } from '../common/StyledInput';

export interface FinancialPlanAssumptionsProps {
    inflation_rate: number;
    simplifyModels: ModelExpectedReturns[];
    customModels: ModelExpectedReturns[];
    updateDiscountRateForModel: typeof updateDiscountRateForModel;
    inflationRateUpdate: typeof inflationRateUpdate;
    getFinancialPlanAssumptions: typeof getFinancialPlanAssumptions;
}

export interface State {
    modelReturns: ModelExpectedReturns[];
}

const StyledText = (props: any) => (<Text {...props} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', ...(props.sx ?? {})}} />)
const StyledTable = (props: any) => (<CommonTable {...props} sx={{ marginTop: '1rem', marginBottom: '2rem', '& th': { textAlign: 'center' }} } />)

class FinancialPlanAssumptions extends Component<FinancialPlanAssumptionsProps, State> {
    state: State = {
        modelReturns: []
    }

    componentDidMount(): void {
        this.props.getFinancialPlanAssumptions();
    }

    componentDidUpdate(prevProps: Readonly<FinancialPlanAssumptionsProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.customModels !== this.props.customModels || prevProps.simplifyModels !== this.props.simplifyModels) {
            this.setState({ modelReturns: [...this.props.simplifyModels, ...this.props.customModels] });
        }
    }

    onUpdateDiscountRate = (item: ModelExpectedReturns, value: string | number) => {
        this.props.updateDiscountRateForModel(item, value);
    }

    renderCustomModelRow = (item: ModelExpectedReturns) => {
        return { key: item.id, title: item.name, values: [<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><StyledInput inputSx={{ font: FONT_STYLES_BY_TYPE.text }} value={item.discountRate ?? 0} onLeave={(value) => this.onUpdateDiscountRate(item, value)} decimalPlaces={2} isPercentage /></Box>] }
    }

    render(): React.ReactNode {
        const { inflation_rate } = this.props;
        return (
            <Page contentsSx={{}}>
                <PageContents
                    sx={{
                        height: '100%',
                        minHeight: '80rem',
                        position: 'relative',
                        marginTop: '2rem',
                        paddingBottom: '5rem',
                        font: FONT_STYLES_BY_TYPE.text,
                        overflowX: 'scroll',
                    }}>
                    <Text type={'h2'}>ALM Assumptions</Text>
                    <Box sx={{ marginTop: '5rem' }}>
                        <Text sx={{fontWeight: 'bold', font: FONT_STYLES_BY_TYPE.h5}}>Inflation Rate</Text>
                        <StyledInput sx={{ marginTop: '1rem' }} inputSx={{ font: FONT_STYLES_BY_TYPE.text }} value={inflation_rate} onLeave={this.props.inflationRateUpdate} decimalPlaces={2} isPercentage />
                    </Box>

                    <Box sx={{ width: '55rem', marginTop: '5rem' }}>
                        <Text sx={{fontWeight: 'bold', font: FONT_STYLES_BY_TYPE.h5}}>Expected Returns</Text>
                    <StyledTable
                        headerColumns={[{
                            key: 'Model Portfolios',
                            children: 'Model Portfolios',
                            sx: {
                                width: '50rem',
                            }
                        },
                        {
                            key: 'Expected Return"',
                            children: 'Expected Return',
                        }]}
                        rows={this.state.modelReturns.map((item) => {
                            if (!item.isSystem) {
                                return this.renderCustomModelRow(item);
                            }

                            return { key: item.id, title: item.name, values: [<StyledText>{((item.discountRate ?? 0) * 100).toFixed(2)}%</StyledText>] };
                        })}
                    />
                    </Box>
                </PageContents>
            </Page>
        );
    }
}

const mapStateToProps = ({financialPlanAssumptions}: ReduxState) => {
    const { inflation_rate, simplifyModels, customModels } = financialPlanAssumptions
    
    return {
        inflation_rate,
        simplifyModels,
        customModels,
    }
}

const FinancialPlanAssumptionsRedux = connect(mapStateToProps, { getFinancialPlanAssumptions, inflationRateUpdate, updateDiscountRateForModel })(FinancialPlanAssumptions as any);

export { FinancialPlanAssumptionsRedux as FinancialPlanAssumptions };