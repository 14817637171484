import axios from 'axios'
import moment from 'moment';
import 'moment-timezone';

import { getBestMatchedTicker } from './portfolioDesigner';

import { DispatchFunction, GetStateFunction } from '../reducers';
import { DUE_DILIGENCE_CHARTS, DUE_DILIGENCE_CHARTS_LOADING, DUE_DILIGENCE_CHARTS_SELECTED, DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS, DUE_DILIGENCE_CHART_ANNOTATIONS, DUE_DILIGENCE_CHART_PROCESSING, DUE_DILIGENCE_RESULTS, DUE_DILIGENCE_SCREENER_PROCESSING, DUE_DILIGENCE_SCREENER_UPDATE, DUE_DILIGENCE_UPDATE } from './types/actions';
import { addError, addSuccessNotification } from './notifications';
import { DueDiligenceChartDTO, DueDiligenceResultDTO, Metric, TimeseriesItems, DEFAULT_CHART_OPTIONS, DEFAULT_NEW_CHART, ChartAnnotations, DueDiligenceChartAnnotationsEdit, PERCENTAGE_METRICS, yAxisDollarFormatter, yAxisPercentageFormatter, ScreenerOptions, DD_STATISTICS_FORMATTING, RC_YAXIS_OPTIONS } from './types/dueDiligence';
import { CorrelationMatrix, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, Ticker } from './types/portfolioDesigner';
import { DueDiligenceState } from '../reducers/DueDiligenceReducer';
import { snakeCaseToWords } from '../common/utils';

import Highcharts from 'highcharts'

const DUE_DILIGENCE_CHARTS_API = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/due-diligence/charts`
const DUE_DILIGENCE_ANNOTATIONS_API = `${DUE_DILIGENCE_CHARTS_API}/annotations`

const DUE_DILIGENCE_SCREENER_API = `${process.env.REACT_APP_ANALYZER_API_URL || ''}/api/analyzer/due-diligence/screener`

const HIGHCHARTS_COLOR_SWAP = {
    0: 3,
    3: 0,
}

export const getDueDiligenceCharts = () => {
    return async(dispatch: DispatchFunction): Promise<void> => {
        try {
                
            const { [0]: { data: chartData }, [1]: { data: annotationCharts }} = await Promise.all([axios.get(DUE_DILIGENCE_CHARTS_API), axios.get(DUE_DILIGENCE_ANNOTATIONS_API)])

            if (!chartData?.success || !annotationCharts?.success) {
                dispatch({ type: DUE_DILIGENCE_CHARTS_LOADING, payload: false })
                dispatch(addError('Load Charts', `An unknown error occurred while retrieving your saved charts`))

                return;
            }
            let annotationsMapped = annotationCharts.items.reduce((accum, item) => {
                const chartAnnotations = accum[item.due_diligence_chart_id] ?? {}

                return { ...accum, [item.due_diligence_chart_id]: { ...chartAnnotations, [item.symbol]: item }}
            }, {})

            annotationsMapped = chartData.items.reduce((accum, item) => {
                const existingAnnotations = accum[item.id] ?? {}

                const chartAnnotations = item.tickers.reduce((accum, ticker) => {
                    const item = accum[ticker] ?? {}

                    return {...accum, [ticker]: item }
                }, existingAnnotations)

                return { ...accum, [item.id]: { ...chartAnnotations }}
            }, annotationsMapped);

            dispatch({ type: DUE_DILIGENCE_CHARTS, payload: chartData.items })
            dispatch({ type: DUE_DILIGENCE_CHART_ANNOTATIONS, payload: annotationCharts.items })
        } catch (e) {
            console.error(e)
            dispatch(addError('Load Charts', `An unknown error occurred while retrieving your saved charts: ${e.toString()}`))
        }
    }
}

export const createDueDiligenceChart = (payload: Partial<DueDiligenceChartDTO>) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<DueDiligenceChartDTO | undefined> => {
        try {
            const { data } = await axios.post(DUE_DILIGENCE_CHARTS_API, payload)
            const { success, item } = data;
    
            if (!success) {
    
                dispatch(addError('Save Chart', 'An unknown error occurred while saving this chart'))
                return undefined;
            }
            const { dueDiligence } = getState()
            const { charts, annotations } = dueDiligence
            const annotationsForNewChart = annotations.filter((annotation) => !annotation.due_diligence_chart_id || annotation.due_diligence_chart_id === payload.id).map((annotation) => ({ ...annotation, due_diligence_chart_id: item.id}))

            const savedAnnotations = await Promise.all(annotationsForNewChart.map((annotation) => saveDueDiligenceChartAnnotationsAPI(annotation)))
            const annotationsUpdate = [...annotations.filter((annotation) => annotation.due_diligence_chart_id !== payload.id), ...savedAnnotations.filter((annotation) => !!annotation)]
            
            await dispatch({ type: DUE_DILIGENCE_CHARTS, payload: [...charts, item] })
            await dispatch({ type: DUE_DILIGENCE_CHART_ANNOTATIONS, payload: annotationsUpdate })
            dispatch(selectDueDiligenceChart(item))

            return item;
        } catch (e) {
            console.error(e)
            dispatch(addError('Save Chart', `An unknown error occurred while saving this chart: ${e.toString()}`))
        }
        return undefined;
    }
}

export const createDueDiligenceChartAnnotations = (payload: DueDiligenceChartAnnotationsEdit, requiresProcessing: boolean) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<DueDiligenceChartDTO | undefined> => {
        try {
            const { dueDiligence } = getState()
            const { selectedChartAnnotations, selectedChartItem, result } = dueDiligence
    
    
            await dispatch({ type: DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS, payload: [...selectedChartAnnotations, {...payload, id:    Math.random() }] })
            dispatch(updateChartOptions(selectedChartItem, result))

            if (requiresProcessing) {
                dispatch(processDueDiligenceChart());
            }
        } catch (e) {
            console.error(e)
            dispatch(addError('Save Chart', `An unknown error occurred while saving this chart annotation: ${e.toString()}`))
        }
        return undefined;
    }
}

export const updateDueDiligenceChartAnnotations = (payload: DueDiligenceChartAnnotationsEdit, requiresProcessing?: boolean) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<DueDiligenceChartDTO | undefined> => {
        try {
            if (!payload.id) {
                return undefined;
            }
            const { dueDiligence } = getState()
            const { selectedChartAnnotations, selectedChartItem, result } = dueDiligence
    
            const intermediateUpdate = selectedChartAnnotations.map((item) => {
                if (item.id === payload.id) {
                    return {...item, ...payload}
                }
    
                return item;
            })
            await dispatch({ type: DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS, payload: intermediateUpdate })

            dispatch(updateChartOptions(selectedChartItem, result))
            if (requiresProcessing) {
                dispatch(processDueDiligenceChart());
            }

        } catch (e) {
            console.error(e)
            dispatch(addError('Save Annotation', `An unknown error occurred while updating this chart annotation: ${e.toString()}`))
        }
        return undefined;
    }
}

export const saveDueDiligenceChartAnnotationsAPI = async (payload: DueDiligenceChartAnnotationsEdit): Promise<any> => {
    try {
        if (!payload.id || !payload.due_diligence_chart_id) {
            return undefined;
        }

        if (payload.id < 1) {
            const { data } = await axios.post(`${DUE_DILIGENCE_CHARTS_API}/${payload.due_diligence_chart_id}/annotations`, payload)

           return data?.item;
        } 
        const { data } = await axios.put(`${DUE_DILIGENCE_CHARTS_API}/${payload.due_diligence_chart_id}/annotations/${payload.id}`, payload)

        return data?.item;
    } catch (e) {
        console.error(e)
    }
    return undefined;
}

export const updateDueDiligenceChart = (id: number, payload: Partial<DueDiligenceChartDTO>, forceRefresh?: boolean) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { dueDiligence } = getState()
            const { selectedChartItem, result } = dueDiligence

            const updateKeys = Object.keys(payload)  as (keyof DueDiligenceChartDTO)[];
            const requiresProcessing = updateKeys.includes('start_date') || updateKeys.includes('end_date') || updateKeys.includes('date_range')  || updateKeys.includes('rc_lag') || updateKeys.includes('rc_tickers');
            const requiresChartOptionUpdate = (!updateKeys.includes('tickers')) && (updateKeys.includes('metrics') || updateKeys.includes('selected_tickers'));
            const mergedPayload = {...selectedChartItem, ...payload};

            if (selectedChartItem.id === id) {
                await dispatch(updatedSelectedDueDiligenceChart(mergedPayload));
                if (requiresChartOptionUpdate) {
                    dispatch(updateChartOptions(mergedPayload, result))
                }
            }

            if (requiresProcessing) {
                dispatch(processDueDiligenceChart());
            }
        } catch (e) {
            console.error(e)
            dispatch(addError('Save Chart', `An unknown error occurred while saving this chart: ${e.toString()}`))
        }
    }
}

export const saveSelectedDueDiligenceChartUpdates = () => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { dueDiligence } = getState()
            const { selectedChartItem, charts, selectedChartAnnotations, annotations } = dueDiligence
    
            const { data } = await axios.put(`${DUE_DILIGENCE_CHARTS_API}/${selectedChartItem.id}`, selectedChartItem)
            const { success, item: updatedItem } = data;
    
            if (!success) {
                dispatch(addError('Save Chart', 'An unknown error occurred while saving this chart'))
    
                return;
            }
            const update = charts.map((item) => {
                if (item.id === selectedChartItem?.id) {
                    return {...item, ...updatedItem}
                }
    
                return item;
            })


            
            const savedAnnotations = await Promise.all(selectedChartAnnotations.map((annotation) => saveDueDiligenceChartAnnotationsAPI(annotation)))
            const annotationsUpdate = [...annotations.filter((annotation) => annotation.due_diligence_chart_id !== selectedChartItem.id), ...savedAnnotations.filter((annotation) => !!annotation)]
            
            await Promise.all([
                dispatch({ type: DUE_DILIGENCE_CHARTS, payload: update }),
                dispatch({ type: DUE_DILIGENCE_CHART_ANNOTATIONS, payload: annotationsUpdate }),
            ])

            dispatch(selectDueDiligenceChart(updatedItem));
            dispatch(addSuccessNotification({ title: selectedChartItem?.name || 'Chart', message: 'Chart saved!' }))
        } catch (e) {
            console.error(e)
            dispatch(addError('Save Chart', `An unknown error occurred while saving this chart: ${e.toString()}`))
        }
    }
}

export const deleteDueDiligenceChart = (id: number) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { dueDiligence } = getState()
            const { charts } = dueDiligence
    
            const intermediateUpdate = charts.filter((item) => item.id !== id)
            dispatch({ type: DUE_DILIGENCE_CHARTS, payload: intermediateUpdate })
            dispatch(clearSelectedDueDiligenceChart())
            dispatch(clearChartOptions())
    
            const { data } = await axios.delete(`${DUE_DILIGENCE_CHARTS_API}/${id}`)
            const { success } = data;
    
            if (!success) {
                dispatch({ type: DUE_DILIGENCE_CHARTS, payload: charts })
                dispatch(addError('Delete Chart', 'An unknown error occurred while deleting this chart'))
            }
        } catch (e) {
            console.error(e)
            dispatch(addError('Delete Chart', `An unknown error occurred while deleting this chart: ${e.toString()}`))
        }
    }
}

export const deleteDueDiligenceChartAnnotations = (chartId: number, itemId: number) => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction): Promise<void> => {
        try {
            const { dueDiligence } = getState()
            const { annotations } = dueDiligence
    
            const intermediateUpdate = annotations.filter((item) => item.id !== itemId)
            dispatch({ type: DUE_DILIGENCE_CHART_ANNOTATIONS, payload: intermediateUpdate })
    
            const { data } = await axios.delete(`${DUE_DILIGENCE_CHARTS_API}/${chartId}/annotations/${itemId}`)
            const { success } = data;
    
            if (!success) {
                dispatch({ type: DUE_DILIGENCE_CHART_ANNOTATIONS, payload: annotations })
                dispatch(addError('Delete Chart', 'An unknown error occurred while deleting this chart'))
            }
        } catch (e) {
            console.error(e)
            dispatch(addError('Delete Chart', `An unknown error occurred while deleting this chart: ${e.toString()}`))
        }
    }
}

export const selectDueDiligenceChart = (item: DueDiligenceChartDTO): any => {
    return async(dispatch: DispatchFunction, state: GetStateFunction) => {
        const { dueDiligence } = state();
        const {annotations, tickers} = dueDiligence;
        const chartAnnotations = annotations.filter((annotation) => annotation.due_diligence_chart_id === item.id);

        const tickersNeedingExpansion = item.tickers.filter((ticker) => !tickers[ticker])
        const tickerItems = await Promise.all(tickersNeedingExpansion.map((ticker) => expandTicker(ticker)))
        const tickersUpdate = tickerItems.reduce((accum, item) => ({...accum, ...item}), {})

        dispatch({ type: DUE_DILIGENCE_CHARTS_SELECTED, payload: { item, annotations: chartAnnotations, tickers: tickersUpdate } })
    }
}

export const updatedSelectedDueDiligenceChart = (item: DueDiligenceChartDTO): any => {
    return async(dispatch: DispatchFunction, state: GetStateFunction) => {
        const { dueDiligence } = state();
        const {selectedChartAnnotations, tickers} = dueDiligence;

        const tickersNeedingExpansion = item.tickers.filter((ticker) => !tickers[ticker])
        const tickerItems = await Promise.all(tickersNeedingExpansion.map((ticker) => expandTicker(ticker)))
        const tickersUpdate = tickerItems.reduce((accum, item) => ({...accum, ...item}), {})

        dispatch({ type: DUE_DILIGENCE_CHARTS_SELECTED, payload: { item, annotations: selectedChartAnnotations, tickers: tickersUpdate } })
    }
}

export const expandTicker = async (ticker: string): Promise<{[key: string]: Ticker | undefined }> => {
    const item = await getBestMatchedTicker(ticker);

    return { [ticker]: item }
}

export const clearSelectedDueDiligenceChart = () => selectDueDiligenceChart({...DEFAULT_NEW_CHART})

export const clearChartOptions = () => updateChartOptions({...DEFAULT_NEW_CHART})

export const updateChartOptions = (item: DueDiligenceChartDTO, result?: DueDiligenceResultDTO): any => {
    return async(dispatch: DispatchFunction, state: GetStateFunction) => {
        const { dueDiligence } = state();
        const {selectedChartAnnotations, selectedChartItem} = dueDiligence;

        const { rolling_correlation, ...resultRest } = result as any || { rolling_correlation: {}};

        
        const dueDiligenceChartOptions = getChartOptions(item, selectedChartAnnotations, { ...resultRest, [Metric.ROLLING_CORRELATION]: { [item.rc_tickers.join(' & ')]: rolling_correlation} });
        dispatch({
            type: DUE_DILIGENCE_RESULTS,
            payload: {
                result,
                dueDiligenceChartOptions,
            }
        })
    }
}

export const processDueDiligenceChart = (): any => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction) => {
        try {
            const { dueDiligence } = getState()
            const { selectedChartItem, selectedChartAnnotations } = dueDiligence

            if (selectedChartItem.tickers.length === 0) {
                dispatch(addError('Process Chart', 'Please select at least one ticker to process this chart'))
                return;
            }

            const enabledBackfills = selectedChartAnnotations.filter((annotation) => annotation.use_backfill).map((annotation) => annotation.symbol)
            const shouldClearRC = (
                selectedChartItem.metrics.includes(Metric.ROLLING_CORRELATION) && selectedChartItem.rc_tickers.length < 2 ||
                selectedChartItem.metrics.includes(Metric.ROLLING_CORRELATION) && (selectedChartItem.rc_lag ?? 0) < 1
            );
            const shouldClearChart = !selectedChartItem.tickers.length || shouldClearRC
            if (shouldClearChart) {
                dispatch(clearChartOptions())
            }

            dispatch({
                type: DUE_DILIGENCE_CHART_PROCESSING,
                payload: true
            })

            const { data } = await axios.post(`${DUE_DILIGENCE_CHARTS_API}/process`, { chart: selectedChartItem, enabled_backfills: enabledBackfills })
            const { success, item } = data;

            if (!success) {
                dispatch({
                    type: DUE_DILIGENCE_CHART_PROCESSING,
                    payload: false
                })
                dispatch(addError('Process Chart', 'An unknown error occurred while processing this chart'))
                return;
            }
            let chartItem = selectedChartItem;
            if (shouldClearRC) {
                chartItem = { ...chartItem, metrics: chartItem.metrics.filter((metric) => metric !== Metric.ROLLING_CORRELATION) }
                dispatch(updatedSelectedDueDiligenceChart(chartItem))
            }
            if (chartItem.metrics.includes(Metric.ROLLING_CORRELATION)) {
                const {rolling_correlation} = item;
                const rollingCorrelation = Object.keys(rolling_correlation).filter((key) => rolling_correlation[key] !== null);
                if (!rollingCorrelation.length) {
                    dispatch(addError('Rolling Correlation', 'No data available for the selected rolling correlation'))
                    chartItem = { ...chartItem, metrics: chartItem.metrics.filter((metric) => metric !== Metric.ROLLING_CORRELATION) }
                    dispatch(updatedSelectedDueDiligenceChart(chartItem))
                }
            }
            if (chartItem.metrics.length > 0) {
                const metricData = item[Metric.TOTAL_RETURN];
                const symbolsIMetricData = Object.keys(metricData);
                const datesForSymbol = Object.keys(metricData[symbolsIMetricData[0]]);

                const startDate = datesForSymbol[0] ?  moment.utc(Number(datesForSymbol[0])).format('MM/DD/YYYY') : chartItem.start_date;
                const endDate = datesForSymbol[datesForSymbol.length - 1] ? moment.utc(Number(datesForSymbol[datesForSymbol.length - 1])).format('MM/DD/YYYY') : chartItem.end_date;
                const metrics = item.hypotheticalDataUsed && (chartItem.metrics.includes(Metric.PRICE) || chartItem.metrics.includes(Metric.PRICE_RETURNS)) ? [Metric.TOTAL_RETURN] : chartItem.metrics;

                chartItem = { ...chartItem, start_date: startDate, end_date: endDate, metrics }
                dispatch(updatedSelectedDueDiligenceChart(chartItem))
            }
            const { correlationMatrix, total_return_statistics } = item;
            const correlationMatrixChart = getCorrelationMatrixChart(correlationMatrix);

            const statisticKeys = Object.keys(total_return_statistics);
            const statisticsTable = Object.keys(total_return_statistics).map((ticker: string) => {
                const item = total_return_statistics[ticker];
                const values = Object.keys(item).map((key) => DD_STATISTICS_FORMATTING[key] ? DD_STATISTICS_FORMATTING[key](item[key]) : item[key]);
                
                return { key: ticker, title: ticker, values }
            })
            const statisticsColumns = statisticKeys.length ? ['Ticker', ...Object.keys(total_return_statistics[statisticKeys[0]])].map((key) => ({ key, children: snakeCaseToWords(key) })) : [];

            dispatch(updateChartOptions(chartItem, item))
            dispatch({
                type: DUE_DILIGENCE_UPDATE,
                payload: {
                    correlationMatrixChart,
                    statisticsTable,
                    statisticsColumns,
                }
            })
        } catch (e) {
            console.error(e)
            dispatch(addError('Save Chart', `An unknown error occurred while saving this chart: ${e.toString()}`))
        }
        dispatch({
            type: DUE_DILIGENCE_CHART_PROCESSING,
            payload: false
        })
    }
}

export const getChartOptions = (item: DueDiligenceChartDTO, chartAnnotations: DueDiligenceChartAnnotationsEdit[], result?: DueDiligenceResultDTO): any => {
    const { metrics, selected_tickers } = item;
    const selectedKeys = metrics;
    const hasPercentageData = selectedKeys.some((key) => PERCENTAGE_METRICS.includes(key));

    const hasSeriesData =  result && selected_tickers.length;

    const sameAxis = metrics.includes(Metric.TOTAL_RETURN) && metrics.includes(Metric.PRICE_RETURNS);

    let lineIndex = 0;
    const series = selectedKeys.reduce((acc: any[], metricKey: Metric, currentIndex: number) => {
        const metric: TimeseriesItems = (result && result[metricKey]) ?? {};
        const metricKeys = Object.keys(metric).filter((key) => selected_tickers.includes(key) || key === item.rc_tickers.join(' & '));
        let seriesName = metricKey.split('_').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' ');
        seriesName = seriesName.charAt(0).toUpperCase() + seriesName.slice(1)
        const data = metricKeys.map((itemKey, index) => {
            const valueMultiplier = PERCENTAGE_METRICS.includes(metricKey) && metricKey !== Metric.ROLLING_CORRELATION ? 100 : 1;
            const timeseries = metric[itemKey];
            const values = Object.keys(timeseries).filter(key => timeseries[key] !== null).map((key) => ({
                x: Number(key),
                y: Number(timeseries[key]) * valueMultiplier,
                metric: metricKey,
                ticker: itemKey,
            }));
            const isPercentage = PERCENTAGE_METRICS.includes(metricKey);
            const annotations = chartAnnotations.find((annotation) => annotation.symbol === itemKey);
            const lastValue = values.length ? values[values.length - 1].y : undefined;
            let lastValueFormatted = '';
            let annualizeReturnFormatted = '';
            if (lastValue !== undefined) {
                const isPositive = lastValue >= 0;
                const color = isPositive ? 'green' : 'red';
                const sign = isPositive ? '+' : '';
                const legendFormatter: (this: any) => string = isPercentage ? yAxisPercentageFormatter : yAxisDollarFormatter;
                lastValueFormatted = ` <span style="color: ${color};">${sign}${metricKey === Metric.ROLLING_CORRELATION ? lastValue?.toFixed(2) || '--' : legendFormatter.bind({ value: lastValue, decimals: 2 })()}</span>`;
            }
            if (metricKey === Metric.TOTAL_RETURN && result?.total_return_statistics[itemKey]) {
                const annualized_return = result.total_return_statistics[itemKey].annualized_return;
                annualizeReturnFormatted = ` / <span style="color: ${annualized_return >= 0 ? 'green' : 'red'};">${(annualized_return * 100)?.toFixed(2) || '--'}% (Ann)</span>`;   
            }

            if (metricKey === Metric.PRICE_RETURNS && result?.price_return_statistics[itemKey]) {
                const annualized_return = result.price_return_statistics[itemKey].annualized_return;
                annualizeReturnFormatted = ` / <span style="color: ${annualized_return >= 0 ? 'green' : 'red'};">${(annualized_return * 100)?.toFixed(2) || '--'}% (Ann)</span>`;   
            }
            
            const defaultColor = HIGHCHARTS_COLOR_SWAP[lineIndex] !== undefined ? Highcharts.getOptions().colors[HIGHCHARTS_COLOR_SWAP[lineIndex]] : Highcharts.getOptions().colors[lineIndex] || undefined;
            const color = annotations?.line_color && currentIndex === 0 ? annotations?.line_color : defaultColor

            lineIndex++;
            return {
                name: `${itemKey} (${seriesName})${lastValueFormatted}${annualizeReturnFormatted}`,
                data: values,
                turboThreshold: 200000,
                lineWidth: annotations?.line_weight ?? 2,
                dashStyle: annotations?.line_type ?? 'Solid',
                color,
                yAxis: sameAxis ? 0 : currentIndex,
            }
        });
        return [...acc, ...data];
    }, []);
    let yAxis: any[] = [];
    if (metrics.length > 0) {
        yAxis = [
            ...yAxis,
            {
                ...DEFAULT_CHART_OPTIONS.yAxis,
                title: {
                    text: metrics[0].split('_').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' '),
                    style: {
                        fontSize: '16px'
                    }
                },
                labels: {
                    ...DEFAULT_CHART_OPTIONS.yAxis.labels,
                    rotation: 0,
                    formatter: PERCENTAGE_METRICS.includes(metrics[0]) ? yAxisPercentageFormatter : yAxisDollarFormatter,
                },
                min: !hasSeriesData ? 0 : undefined,
                max: !hasSeriesData ? 100 : undefined,
                ...(metrics[0] === Metric.ROLLING_CORRELATION ? RC_YAXIS_OPTIONS : {})
            }]
    }

    if (metrics.length > 1 && !sameAxis) {
        yAxis = [
            ...yAxis,
            {
                ...DEFAULT_CHART_OPTIONS.yAxis,
                title: {
                    text: metrics[1].split('_').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' '),
                    style: {
                        fontSize: '16px'
                    }
                },
                labels: {
                    ...DEFAULT_CHART_OPTIONS.yAxis.labels,
                    rotation: 0,
                    formatter: PERCENTAGE_METRICS.includes(metrics[1]) ? yAxisPercentageFormatter : yAxisDollarFormatter,
                },
                min: !hasSeriesData ? 0 : undefined,
                max: !hasSeriesData ? 100 : undefined,
                opposite: hasPercentageData ? true : false,
                ...(metrics[1] === Metric.ROLLING_CORRELATION ? RC_YAXIS_OPTIONS : {})
            }]
    }

    return {
        ...DEFAULT_CHART_OPTIONS,
        xAxis: {
            ...DEFAULT_CHART_OPTIONS.xAxis,
            interval: !hasSeriesData ? 5 : undefined,
            min: !hasSeriesData ? DEFAULT_MIN_DATE.getTime() : undefined,
            max: !hasSeriesData ? DEFAULT_MAX_DATE.getTime() : undefined,
        },
        yAxis,
        legend: {
            ...DEFAULT_CHART_OPTIONS.legend,
            enabled: hasSeriesData,
        },
        time:{
            useUTC:true
        },
        series: hasSeriesData ? series : DEFAULT_CHART_OPTIONS.series,
    }
}

export const screenerUpdate = (payload: Partial<DueDiligenceState>): any => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction) => {
        dispatch({
            type: DUE_DILIGENCE_SCREENER_UPDATE,
            payload
        })
    }
}

export const processScreener = (options: ScreenerOptions): any => {
    return async(dispatch: DispatchFunction, getState: GetStateFunction) => {
        try {
            if (Object.keys(options).length === 0) {
                dispatch({
                    type: DUE_DILIGENCE_SCREENER_UPDATE,
                    payload: { screenerResults: [] },
                })
                return;
            }
            dispatch({
                type: DUE_DILIGENCE_SCREENER_PROCESSING,
                payload: true
            })

            const { data } = await axios.post(DUE_DILIGENCE_SCREENER_API, options)
            const { success, items } = data;

            if (!success) {
                dispatch({
                    type: DUE_DILIGENCE_SCREENER_PROCESSING,
                    payload: false
                })
                dispatch(addError('Screener', 'An unknown error occurred while updating the screener options'))
                return;
            }

            const { dueDiligence } = getState()
            const { selectedChartItem } = dueDiligence
            const { selected_tickers } = selectedChartItem;

            const itemsWithChecks = items.map((item: any) => {
                const isChecked = selected_tickers.includes(item.symbol_display);
                return { ...item, checked: isChecked }
            });

            dispatch({
                type: DUE_DILIGENCE_SCREENER_UPDATE,
                payload: { screenerResults: itemsWithChecks },
            })
        } catch (e: any) {
            console.error(e)
            dispatch(addError('Screener', `An unknown error occurred while updating the screener options: ${e.toString()}`))
        }
        dispatch({
            type: DUE_DILIGENCE_SCREENER_PROCESSING,
            payload: false
        })
    }
}


export const getCorrelationMatrixChart = (correlationMatrix: CorrelationMatrix) => {

    const categories = Object.keys(correlationMatrix)
    
    const data = categories.map((yCategory, yIndex) => {
        const items = categories.map((xCategory, xIndex) => {
            const value = xIndex > yIndex ? '' : correlationMatrix[xCategory][yCategory];
            return value === ''
              ? { x: xIndex, y: yIndex, value: null, color: 'white', borderColor: 'white' } // Set color to white for blank values
              : [xIndex, yIndex, value];
        });
        return items;
    }).flat();


    return {

        chart: {
            type: 'heatmap',
            marginTop: 45,
            marginBottom: 80,
            plotBorderWidth: 1,
        },


        title: {
            text: '',
        },

        xAxis: {
            categories,
            opposite: true,
            labels: {
                style: {
                    fontSize: '14px'
                }
            },
        },

        yAxis: {
            categories,
            reversed: true,
            title: null,
            labels: {
                style: {
                    fontSize: '14px'
                }
            },
        },

        colorAxis: {
            min: -1,
            max: 1,
            minColor: '#00FF00',  // Green
            maxColor: '#FF0000'   // Red
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },

        tooltip: {
            format: '<b>{series.xAxis.categories.(point.y)}</b> / <b>{series.yAxis.categories.(point.x)}</b><br>' +
                '<b>{point.value}</b> <br>' +
                ''
        },
        series: [{
            name: 'Correlation Matrix',
            borderWidth: 1,
            data: data,
            dataLabels: {
                enabled: true,
                color: '#000000',
                style: {
                    fontSize: '14px'
                }
            }
        }],
    };
}