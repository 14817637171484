import { Box } from "@mui/material"
import { Component } from "react"
import { connect } from 'react-redux'
import Text from "../../common/Text"
import { withClientParameter } from "../../common/withClientParameter"
import Button from "../../common/Button"
import { SelectOption } from "../../../common/types"
import { historicalAnalysis, updatePortfolioForType, updateConfigsForType, updateModelsForType, updateParameter, loadModelOptions, loadConfigurationOptions, runAnalyzer, runPortfolioOptimization, getTradingStartDate, createAnalyzerRequestId, downloadReport } from "../../../actions/portfolioDesigner"
import { TickerAllocation, AnalyzeResultsDTO, PortfolioDesignerConfigurationType, HistoricalAnalysisResultsDTO, ActionStatus, ResultsTableData, TYPE_TABLE_MAPPING, ReportResourceItem, DateLogs, ReportItem, ReportOptions } from "../../../actions/types/portfolioDesigner"
import { addError, addSuccessNotification } from "../../../actions/notifications"
import { DatePicker } from "../../common/styled/DatePicker"
import moment from "moment"

import { withWindowDimensions, WithWindowDimensionsProps } from "../../common/withWindowDimensions"
import { FONT_STYLES_BY_TYPE } from "../../common/styled/Text"
import { TextFieldItem } from "../../common/FormField"
import { ClipLoader } from "react-spinners"
import { withAuth, withAuthProps } from "../../../common/hooks/withAuth"
import { ReduxState } from "../../../reducers"
import { ChartOptions } from "../../OptimizedPortfolio/chartOptions"
import { ResultDateLogs } from "../ResultDateLogs"
import ResultsTab from "../ResultTabs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { openLink } from "../../../common/utils"
import ReportOptionsModal from "../ReportOptionsModal"
import EfficientFrontier from "../ResultTabs/EfficientFrontier"

export interface PortfolioDesignerProps extends WithWindowDimensionsProps, withAuthProps {
    benchmarkPortfolio: TickerAllocation[]
    portfolioPortfolio: TickerAllocation[]
    benchmarkRebalancingFrequency: SelectOption
    portfolioRebalancingFrequency: SelectOption
    backTestingStart?: Date
    backTestingEnd?: Date
    customRangeStart?: Date
    customRangeEnd?: Date
    results?: AnalyzeResultsDTO
    allHistoricalEvents: any[]
    minDate: Date,
    maxDate: Date,
    dates: DateLogs,
    strategyName: string
    updatedAnalysis?: HistoricalAnalysisResultsDTO
    historicalAnalysisCustomRows: any[]
    missingPrices: string[] | undefined,
    snapshot: ResultsTableData
    snapshotCorrelationMatrixChart: ChartOptions
    runningAnalyzer: boolean
    status?: ActionStatus
    runningHistoricalAnalysis: boolean
    reportItems: ReportResourceItem[]
    requestId?: string
    excelUrl?: string
    reportUrl?: string
    effChart?: any
    effTable?: any
    clickedMarkers: string[]
    runAnalyzer: typeof runAnalyzer,
    runPortfolioOptimization: typeof runPortfolioOptimization,
    loadModelOptions: typeof loadModelOptions,
    loadConfigurationOptions: typeof loadConfigurationOptions,
    addError: (title: string, message: string) => any,
    addSuccessNotification: (notification: { title?: string, message: string }) => any,
    updatePortfolioForType: (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => any
    updateConfigsForType: (type: PortfolioDesignerConfigurationType, portfolio: SelectOption[]) => any
    updateModelsForType: (type: PortfolioDesignerConfigurationType, portfolio: SelectOption[]) => any
    updateParameter: (payload: any) => any
}
export interface PortfolioDesignerState {
    runningHistoricalAnalysis: boolean,
    runningReportDownload: boolean,
    runningExcelDownload: boolean,
    showReportOptions: boolean,
}
class BackTesting extends Component<PortfolioDesignerProps, PortfolioDesignerState> {
    resultsRef?: any

    state = {
        runningHistoricalAnalysis: false,
        runningReportDownload: false,
        runningExcelDownload: false,
        showReportOptions: false,
    }

    componentDidUpdate(prevProps: Readonly<PortfolioDesignerProps>, prevState: Readonly<PortfolioDesignerState>, snapshot?: any): void {
        if (prevProps.results !== this.props.results ||
            prevProps.customRangeStart !== this.props.customRangeStart ||
            prevProps.customRangeEnd !== this.props.customRangeEnd ||
            prevProps.updatedAnalysis !== this.props.updatedAnalysis ||
            prevState.runningHistoricalAnalysis !== this.state.runningHistoricalAnalysis) {
            this.onUpdateHistoricalAnalysisCustomRows()
        }
    }

    scrollToBottom = () => {
        this.resultsRef.scrollIntoView({behavior: "smooth", block: "end", inline: "start"})
    }

    onBackTestingStartUpdated = async (value: Date) => {
        this.props.updateParameter({ backTestingStart: value })
        try {
            const { benchmarkPortfolio, portfolioPortfolio } = this.props
            const tradingDate = await getTradingStartDate(benchmarkPortfolio, portfolioPortfolio, value)
            
            this.props.updateParameter({ backTestingStart: tradingDate })
        } catch (e) {}
    }

    onBackTestingEndUpdated = (value: Date) => {
        this.props.updateParameter({ backTestingEnd: value })
    }

    onCustomRangeStartUpdated = async (customRangeStart: Date) => {
        const { results, customRangeEnd } = this.props
        this.props.updateParameter({ customRangeStart });
        if (!results || !customRangeEnd) {
            return
        }

        this.setState({ runningHistoricalAnalysis: true })
        const updatedAnalysis = await historicalAnalysis({ id: results.simulatedPortfolioValuesId, startDate: moment(customRangeStart).format('MM/DD/YYYY'), endDate: moment(customRangeEnd).format('MM/DD/YYYY') })
        if (updatedAnalysis?.error) {
            this.props.addError('Historical Scenario Analysis', updatedAnalysis.error)
        }
        this.setState({ runningHistoricalAnalysis: false })
        
        if (updatedAnalysis?.success) {
            this.props.updateParameter({ updatedAnalysis })
        }
    }

    onCustomRangeEndUpdated = async (customRangeEnd: Date) => {
        const { results, customRangeStart } = this.props
        await this.props.updateParameter({ customRangeEnd });
        
        if (!results || !customRangeStart) {
            return
        }

        this.setState({ runningHistoricalAnalysis: true })

        const updatedAnalysis = await historicalAnalysis({ id: results.simulatedPortfolioValuesId, startDate: moment(customRangeStart).format('MM/DD/YYYY'), endDate: moment(customRangeEnd).format('MM/DD/YYYY') })
        if (updatedAnalysis?.error) {
            this.props.addError('Historical Scenario Analysis', updatedAnalysis.error)
        }
        
        this.setState({ runningHistoricalAnalysis: false })
        
        if (updatedAnalysis?.success) {
            this.props.updateParameter({ updatedAnalysis })
        }
    }

    onUpdateHistoricalAnalysisCustomRows = () => {
        const historicalAnalysisCustomRows = [
            this.getCustomRangeRow(),
        ]

        this.props.updateParameter({ historicalAnalysisCustomRows })
    }

    getCustomRangeDateRow = () => {
        const { backTestingStart, backTestingEnd, customRangeStart, customRangeEnd, effChart, effTable } = this.props
        return (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Text sx={{ width: '13rem' }}>Custom Range:</Text>
                    <DatePicker
                        date={customRangeStart ?? ''}
                        format={'MM/dd/yyyy'}
                        minDate={backTestingStart}
                        maxDate={backTestingEnd}
                        placeholderText={customRangeStart ? customRangeStart : '__/__/____'}
                        onDateChanged={this.onCustomRangeStartUpdated} 
                        inputSx={{
                            height: 'unset',
                            padding: 'unset',
                            width: '10rem',
                            textAlign: 'center',
                        }}
                        bottomPlacementSx={{
                            marginTop: '2rem',
                        }}
                        plain
                    />
                    <Box>-</Box>
                    <DatePicker
                        date={customRangeEnd ?? ''}
                        format={'MM/dd/yyyy'}
                        minDate={backTestingStart}
                        maxDate={backTestingEnd}
                        placeholderText={customRangeEnd ? customRangeEnd : '__/__/____'}
                        onDateChanged={this.onCustomRangeEndUpdated} 
                        inputSx={{
                            height: 'unset',
                            padding: 'unset',
                            width: '10rem',
                            textAlign: 'center',
                        }}
                        bottomPlacementSx={{
                            marginTop: '2rem',
                        }}
                        plain
                    />
                </Box>
        )
    }

    getCustomRangeRow = () => {
        const { updatedAnalysis } = this.props
        const { runningHistoricalAnalysis } = this.state

        const baseItem = { title: this.getCustomRangeDateRow(), key: 'Custom Range' }

        if (runningHistoricalAnalysis) {
            return { ...baseItem,  values: [<ClipLoader loading={true} size={14} />, <ClipLoader loading={true} size={14} />] }
        }

        if (!updatedAnalysis) {
            return { ...baseItem,  values: ['0.00%', '0.00%'] }
        }

        return { ...baseItem,  values: [updatedAnalysis["Custom Range"]?.Benchmark || '', updatedAnalysis["Custom Range"]?.Strategy || ''] }
    }

    onStrategyNameChange = (value: string) => {
        this.props.updateParameter({ strategyName: value })
    }

    updateSnapshotPortfolioLoading = (type: PortfolioDesignerConfigurationType) => {
        const { snapshot } = this.props
        this.props.updateParameter({ snapshot: {...snapshot, data: {
            ...snapshot.data,
            [TYPE_TABLE_MAPPING[type]]: {
                "1Yr Equity Beta": <ClipLoader loading={true} size={14} />,
                "1Yr Volatility": <ClipLoader loading={true} size={14} />,
                "Distribution Yield": <ClipLoader loading={true} size={14} />,
                "Effective Duration": <ClipLoader loading={true} size={14} />,
                "Expense Ratio": <ClipLoader loading={true} size={14} />,
            }
        }}})
    }

    onRunPortfolioDesigner = async() => {
        const requestId = createAnalyzerRequestId();
        this.props.updateParameter({ requestId, excelUrl: undefined, reportUrl: undefined, results: undefined, effChart: undefined, effTable: undefined })
        this.props.runAnalyzer(requestId)
        this.props.runPortfolioOptimization(requestId)
    }

    updatePortfolioForType = (type: PortfolioDesignerConfigurationType, portfolio: TickerAllocation[], changedItem?: TickerAllocation, itemUpdateType?: 'weight' | 'ticker') => {
        this.props.updatePortfolioForType(type, portfolio, changedItem, itemUpdateType)
        this.updateSnapshotPortfolioLoading(type)
    }

    onDownloadExcel = async () => {
        const { requestId, excelUrl } = this.props
        if (!requestId) {
            return
        }
        if (excelUrl) {
            openLink(excelUrl)
            return
        }
        try {
            this.setState({ runningExcelDownload: true })
            const { url } = await downloadReport(requestId, 'Excel')
            
            openLink(url)
            this.props.updateParameter({ excelUrl: url })
            
            this.setState({ runningExcelDownload: false })
        } catch (e) {
            this.setState({ runningExcelDownload: false })
            this.props.addError('Download Excel', 'There was an error downloading the excel file.')
        }
    }

    onDownloadReport = async (options: ReportOptions) => {
        const { requestId, clickedMarkers } = this.props
        if (!requestId) {
            return
        }

        try {
            this.setState({ runningReportDownload: true })
            
            const { url } = await downloadReport(requestId, 'HTML', {...options, data: { efficientFrontier: { clickedMarkers } }})
            openLink(url)
            this.props.updateParameter({ reportUrl: url })
            
            this.setState({ runningReportDownload: false })
        } catch (e) {
            this.setState({ runningReportDownload: false })
            this.props.addError('Download Report', 'There was an error downloading the report.')
        }
        
    }

    onShowReportOptions = () => {
        this.setState({ showReportOptions: true })
    }

    onCloseReportOptions = () => {
        this.setState({ showReportOptions: false })
    }

    onReportOptionsSave = (options: ReportOptions) => {
        this.onCloseReportOptions()
        this.onDownloadReport(options)
    }

    render() {
        const {  } = this.props
        const { backTestingStart, backTestingEnd, minDate, maxDate, results, dates, strategyName: strategyName, runningAnalyzer, status, missingPrices, effChart, effTable } = this.props
        const reportDisabled = !results?.success || !effChart || !effTable

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column',}}>
                <ReportOptionsModal
                    visible={this.state.showReportOptions}
                    onCancel={this.onCloseReportOptions}
                    onSave={this.onReportOptionsSave}
                />
               <Box sx={{ 
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row', 
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: '1rem',
                        paddingTop: '1rem',
                        }}>
                            <Box>
                                <Text>Start Date</Text>
                                <DatePicker
                                    date={backTestingStart ?? ''}
                                    format={'MM/dd/yyyy'}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onDateChanged={this.onBackTestingStartUpdated} 
                                />
                            </Box>
                            <Box sx={{ marginLeft: '1rem' }}>
                                <Text>End Date</Text>
                                <DatePicker
                                    date={backTestingEnd ?? ''}
                                    format={'MM/dd/yyyy'}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onDateChanged={this.onBackTestingEndUpdated} 
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: '1rem' }}>
                        <TextFieldItem
                            sx={{ 
                                width: '30rem',
                                height: '5rem',
                            }}
                            title={<Text>Report Title</Text>}
                            value={strategyName}
                            onValueChange={this.onStrategyNameChange}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginLeft: {
                            xs: 'unset',
                            md: '2rem',
                        },
                        marginBottom: '2rem',
                    }}>
                        <Button autoFit sx={{ width: '15rem',  }} title={'Run'} onClick={this.onRunPortfolioDesigner} loading={runningAnalyzer} inlineLoading/>
                        {runningAnalyzer && status &&
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '1rem',
                            }}>
                                <Text>{status.status}</Text>
                            </Box>
                        }
                    </Box>
                </Box>
                    {missingPrices && missingPrices.length > 0 &&
                        <Box sx={{ marginBottom: '1rem' }}>
                            <FontAwesomeIcon size={'2x'} icon={faWarning} color={'#eed202'} /> <Text sx={{ marginLeft: '.5rem' }}>Please note the following tickers were missing data: {missingPrices.join(', ')}</Text>
                        </Box>
                    }
                {results?.success &&
                    <Box
                        ref={view => this.resultsRef = view} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '2rem',
                        }}>
                            <Box sx={{ font: FONT_STYLES_BY_TYPE.text_small, marginBottom: '1rem' }}>
                                Backtest from: {dates.backtest_range}.
                                {results.hypotheticalDataUsed && <Text sx={{ font: FONT_STYLES_BY_TYPE.text_small, fontWeight: 'bold', color: 'red' }}><br />NOTE THAT HYPOTHETICAL BACKTEST DATA WAS COMBINED WITH LIVE DATA. </Text>}
                                {' Please see bottom of page for complete details on underlying data.'}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
                            <Button 
                                sx={{ width: '20rem', marginRight: '1rem' }}
                                title={'Download Report'}
                                onClick={this.onShowReportOptions}
                                loading={this.state.runningReportDownload}
                                inlineLoading
                                disabled={reportDisabled}
                            />
                            <Button 
                                sx={{ width: '20rem' }}
                                title={'Download Excel'}
                                onClick={this.onDownloadExcel}
                                loading={this.state.runningExcelDownload}
                                inlineLoading
                                disabled={reportDisabled}
                            />
                        </Box>
                        {reportDisabled &&
                            <Box sx={{ mt: '2rem'}}>
                                <Text>Report is not ready yet, download links will be available in a few seconds.</Text>        
                            </Box>
                        }
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '1rem',
                        }}>
                        </Box>
                        <ResultsTab items={this.props.reportItems} />
                        <ResultDateLogs
                            logs={dates}
                        /> 
                    </Box>
                }
            </Box>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { benchmarkPortfolio, portfolioPortfolio, benchmarkRebalancingFrequency, portfolioRebalancingFrequency,
            strategyName, results, dates, backTestingStart, backTestingEnd, customRangeStart, customRangeEnd, 
            minDate, maxDate, updatedAnalysis, historicalAnalysisCustomRows, snapshot, backtestMissingPrices,
            snapshotCorrelationMatrixChart, runningAnalyzer, status, runningHistoricalAnalysis, reportItems,
            effChart, effTable, requestId, excelUrl, reportUrl, clickedMarkers } = portfolioDesigner

    return { 
        benchmarkPortfolio, portfolioPortfolio, benchmarkRebalancingFrequency, portfolioRebalancingFrequency,
        strategyName, results, dates, backTestingStart, backTestingEnd, customRangeStart, customRangeEnd,
        minDate, maxDate, updatedAnalysis, historicalAnalysisCustomRows, snapshot, missingPrices: backtestMissingPrices, snapshotCorrelationMatrixChart,
        runningAnalyzer, status, runningHistoricalAnalysis, reportItems, requestId, excelUrl, reportUrl, effChart, effTable, clickedMarkers
    }
}

export default withAuth(withWindowDimensions(withClientParameter(connect(mapStateToProps, { runAnalyzer, addError, addSuccessNotification, updatePortfolioForType, updateConfigsForType, updateModelsForType, updateParameter, loadModelOptions, loadConfigurationOptions, runPortfolioOptimization })(BackTesting as  any))))
