import { Component } from 'react'
import { connect } from 'react-redux';
import { Box } from '@mui/material'
import ConfirmModal from '../common/ConfirmModal';
import { ReduxState } from '../../reducers';
import { ReportSet, ReportOptions, ReportItem, SavedReport } from '../../actions/types/portfolioDesigner';
import { ReportSetComponent } from './ReportSetComponent';
import Text from '../common/Text';
import { Select } from '../common/styled/Select';
import Button from '../common/Button';
import SaveAsModal from '../CustomCMA/SaveAsModal';
import { SelectOption } from '../../common/types';
import { deleteSavedReports, loadSavedReports } from '../../actions/portfolioDesigner';
import { createSavedReports } from '../../actions/portfolioDesigner';
import ConfirmDelete from '../common/ConfirmDelete';


interface ComponentProps {
    onSave: (options: ReportOptions) => void
    onCancel: () => void
    visible: boolean
}

const REPORT_SETS: ReportSet[] = [
    { 
        "key": "statistics",
        "label": "Statistics (Readonly)",
        "readonly": true,
        "items": [
            { "key": "key_statistics", "label": 'Key Statistics', selected: true },
            { "key": "moment_statistics", "label": 'Moment Statistics', selected: true },
            { "key": "annual_returns", "label": 'Annual Returns', selected: true },
            { "key": "cumulative_return", "label": 'Cumulative Return', selected: true },
            { "key": "drawdown", "label": 'Drawdown', selected: true },
        ]
    },
    { 
        "key": "performance",
        "label": "Performance",
        "chunks": 2,
        "items": [
            { "key": "average_returns_by_benchmark_regime", "label": 'Average Returns by Benchmark Regime', selected: true },
            { "key": "fund_contribution_vs_benchmark", "label": 'Fund Contribution vs Benchmark', selected: true },
            { "key": "exposure_percent_of_portfolio", "label": 'Exposure (% of Portfolio)', selected: true },
            { "key": "rolling_12_month_beta", "label": 'Rolling 12-Month Beta', selected: true },
            { "key": "relative_performance_portfolio_vs_benchmark", "label": 'Relative Performance - Portfolio Vs Benchmark', selected: true },
            { "key": "return_histograms", "label": 'Return Histograms', selected: true },
            { "key": "portfolio_vs_benchmark", "label": 'Portfolio vs Benchmark ', selected: true },
        ]
    },
    { 
        "key": "misc",
        "label": "Misc",
        "items": [
            { "key": "fixed_income_breakdown", "label": 'Fixed Income Breakdown', selected: true },
            { "key": "equity_breakdown", "label": 'Equity Breakdown', selected: true },
            { "key": "correlation_matrix", "label": 'Correlation Matrix', selected: true },
            { "key": "efficient_frontier", "label": 'Efficient Frontier', selected: true },
            { "key": "performance_attribution", "label": 'Performance Attribution', selected: true },
        ]
    },
];

interface ReduxStateProps { 
    savedReports: SelectOption<SavedReport>[]
}

interface ReduxActions {
    loadSavedReports: () => void
    createSavedReports: (name: string, items: string[]) => void
    deleteSavedReports: (id: number) => void
}

interface ReportOptionsModalModalState { 
    sets: ReportSet[]
    selectedSavedReport: SelectOption<SavedReport> | null
    showSaveAsModal: boolean
    showConfirmDelete: boolean
}

type ReportOptionsModalModalProps = ComponentProps & ReduxStateProps & ReduxActions;

class ReportOptionsModal extends Component<ReportOptionsModalModalProps, ReportOptionsModalModalState> {
    selectedLinePath: any;

    constructor(props: ReportOptionsModalModalProps) {
        super(props);
        this.state = { 
            sets: [...REPORT_SETS],
            selectedSavedReport: null,
            showSaveAsModal: false,
            showConfirmDelete: false
        }
    }

    componentDidMount(): void {
        this.props.loadSavedReports()
        
    }

    onReportSetChanged = (set: ReportSet) => {
        const updatedSets = this.state.sets.map((item) => {
            if (item.key === set.key) {
                return set
            }
            return item
        });
        this.setState({ sets: updatedSets })
    }

    onSave = () => {
        const { sets } = this.state
        const cleaned = sets.filter(item => !item.readonly)
        const items: ReportItem[] = cleaned.reduce((acc: ReportItem[], set) => {
            const selectedItems = set.items.filter(item => item.selected)

            return [...acc, ...selectedItems]
        }, [] as ReportItem[])
        const options: ReportOptions = {
            items: items
        }
        this.props.onSave(options)
    }

    onSavedReportSelected = (value: SelectOption<SavedReport>) => {
        const { sets } = this.state
        this.setState({ selectedSavedReport: value })
        const updatedSets = sets.map((set) => {
            const updatedItems = set.items.map((item) => {
                return {
                    ...item,
                    selected: value.item?.items.includes(item.key) || false
                }
            })
            return { ...set, items: updatedItems }
        });
        this.setState({ sets: updatedSets })
    }

    onSaveAsReport = () => {
        this.setState({ showSaveAsModal: true })
    }

    onDeleteSavedReport = () => {
        const { selectedSavedReport } = this.state
        if (!selectedSavedReport) {
            return
        }
        this.props.deleteSavedReports(selectedSavedReport.value as number)
        this.setState({ selectedSavedReport: null, showConfirmDelete: false })
    }

    onCloseSaveAsModal = () => {
        this.setState({ showSaveAsModal: false })
    }

    onSaveReportConfirmed = (name: string) => {
        const items = this.state.sets.reduce((acc: string[], set) => {
            const selectedItems = set.items.filter(item => item.selected)
            return [...acc, ...selectedItems.map(item => item.key)]
        }, [] as string[])

        this.props.createSavedReports(name, items)
        this.setState({ showSaveAsModal: false })
    }

    onShowConfirmDelete = () => {
        this.setState({ showConfirmDelete: true })
    }

    onCloseConfirmDelete = () => {
        this.setState({ showConfirmDelete: false })
    }

    render() {
        const { visible, savedReports } = this.props
        const { sets, showSaveAsModal, selectedSavedReport, showConfirmDelete } = this.state
        
        return (
            <ConfirmModal
                visible={visible}
                title={`Report Options`}
                confirmLabel={'Generate'}
                cancelLabel={'Cancel'}
                onConfirm={this.onSave}
                onCancel={this.props.onCancel}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <SaveAsModal
                            size={'sm'}
                            visible={showSaveAsModal}
                            name={selectedSavedReport?.label ? selectedSavedReport.label as string : '' }
                            onSave={this.onSaveReportConfirmed}
                            onCancel={this.onCloseSaveAsModal}
                        />
                        <ConfirmDelete
                            size={'sm'}
                            visible={showConfirmDelete}
                            title={selectedSavedReport?.label ? selectedSavedReport.label as string : 'Saved Report' }
                            onDelete={this.onDeleteSavedReport}
                            onCancel={this.onCloseConfirmDelete}>
                                <Text>Are you sure you want to delete this saved report?</Text>
                        </ConfirmDelete>
                        <Box sx={{ display: 'flex', marginBottom: '1.5rem', alignItems: { xs: 'unset', xl: 'center' }, flexDirection: { xs: 'column', xl: 'row' } }}>
                            <Text sx={{ width: '15rem', minWidth: '15rem', marginRight: '1rem' }}>Saved Reports</Text>
                            <Select sx={{ width: '35rem', marginRight: '1rem' }} options={savedReports} value={selectedSavedReport} onChange={this.onSavedReportSelected} />

                            <Box sx={{ marginTop: { xs: '1rem', xl: 'unset' }, display: 'flex', alignItems: 'center' }}>
                                <Button autoFit sx={{ height: '4.5rem' }} title={'Save As'} onClick={this.onSaveAsReport} />
                                {selectedSavedReport && <Button autoFit sx={{ height: '4.5rem' }} title={'Delete'} onClick={this.onShowConfirmDelete} />}
                            </Box>
                        </Box>
                        {sets.map((set, index) => <ReportSetComponent item={set} onReportSetChanged={this.onReportSetChanged} />)}
                    </Box>
            </ConfirmModal>
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState) => ({ 
    savedReports: portfolioDesigner.savedReports
})

export default connect(mapStateToProps, { loadSavedReports, createSavedReports, deleteSavedReports })(ReportOptionsModal)