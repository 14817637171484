import { Component } from "react"
import { connect } from 'react-redux'
import { ResultTable } from "../../../ResultTable"
import { ReduxState } from "../../../../../reducers"
import { ChartOptions } from "../../../../../common/types"
import Chart from "../../../../../components/common/Chart"
import { Box } from "@mui/material"
import Text from "../../../../../components/common/Text"

export interface PriceEarningsChartTableProps {
    priceOverEarningsChart: ChartOptions
}
export interface PriceEarningsChartTableState { }
class PriceEarningsChart extends Component<PriceEarningsChartTableProps, PriceEarningsChartTableState> {
    render() {
        const { priceOverEarningsChart } = this.props
        if (!priceOverEarningsChart) {
            console.log('PriceEarningsChart RETURNING NULL', priceOverEarningsChart)
            return null;
        }

        return (
                <Chart
                    id={'priceOverEarningsChart'}
                    chartOptions={priceOverEarningsChart}
                    title={'P/E Distribution'}
                    sx={{
                        width: '50rem'
                    }}
                />
        )
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { priceOverEarningsChart } = portfolioDesigner

    return { priceOverEarningsChart  }
}

export default connect(mapStateToProps)(PriceEarningsChart as  any)